import React, { createContext, useContext, useEffect, useState } from 'react'
import { checkSubscriptionStatus, getAccountProfile, getConstantProfile, getHeaderApi, isUserPremium } from '../../Utilities/controller';
import { func } from '../../Utilities/logFunc';
import { toast } from 'react-toastify';
import Loader from '../Common/Loader';
import { logout } from '../../Utilities/logout';

const ProfileDataContext = createContext()

const ProfileDataProvider = ({children}) => {
    const [profileData, setProfileData] = useState()
    const [loading, setLoading] = useState(false)
    const [nonPremiumUser, setNonPremiumUser] = useState()
    const [liteProfile, setLiteProfile] = useState()
    const [callChildren, setCallChildren] = useState(false)
    const fetchAccountProfile = async () => {
      try {
        const data = await getConstantProfile();
        if(data?.data){
          setLiteProfile(data?.data)
          localStorage.setItem("profile",JSON.stringify(data?.data))
          setLoading(false)
          setCallChildren(true)
        }
        else
        setLoading(false)
      } catch (error) {
        setLoading(false)
       toast.error(error?.response?.errors?.[0]?.msg)
      }
    };
    const getAccountProfileDetails = async () => {
        setLoading(true)
        try {
          const res = await getHeaderApi();
        //  console.log("profile data---------------", res?.data)
          if (res?.data) {
            setProfileData(res?.data);
           
            // localStorage.setItem("profile", JSON.stringify(res?.data));
            // localStorage.setItem(
            //   "currentD11",
              
            // );
         
                // let userId = JSON.parse(localStorage.getItem('profile'))?.item?.user_id
                // const resProfile = await getHeaderApi();
                // func1("premium user********",profileData)
                const res1 = await isUserPremium(res?.data?.item?.user_id)
                // func1(JSON.parse(localStorage.getItem('profile'))?.item?.user_id)
                // console.log("**************************************res1?.data*****************************",res1?.data)
              localStorage.setItem("premiumUser",res1?.data?.data?.[0]?.param?.isPremium)
              localStorage.setItem("planName",res1?.data?.data?.[0]?.param?.planDetails?.planName || "Free")
              // console.log(res1?.data?.data?.[0]?.param?.nonPremiumUser)
              setNonPremiumUser(res1?.data?.data?.[0]?.param?.nonPremiumUser)
              // localStorage.setItem("freeContest",res1?.data?.data?.[0]?.param?.nonPremiumUser?.noOfFreeContest)
              // localStorage.setItem("freeTournament",res1?.data?.data?.[0]?.param?.nonPremiumUser?.noOfFreeTournament)
              // localStorage.setItem("premiumUser",true)
            
              fetchAccountProfile()
            
            }
          
            else
            setLoading(false)
         
        } catch (error) {
          
      if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
      logout()
            toast.error(error?.response?.data?.errors?.[0]?.msg)
          func("profile error 42", error);
          setLoading(false)
        }
      };
    
      const subscriptionStatus = async() => {
        try {
          const res = await checkSubscriptionStatus();
          // console.log(res.data, '-----------check subscri')
          setLoading(true)
          if(res?.data?.status == 'ACTIVE') {
            getAccountProfileDetails()
          }
          else{
            logout()
          // getAccountProfileDetails()

          }
        } catch (error) {
          console.log(error)
          logout()
          // getAccountProfileDetails()
        }
      }  
      const updateProfileData = (newData)=>{
        setProfileData(newData)
        fetchAccountProfile();
      }
      useEffect(()=>{
        // getAccountProfileDetails()
        // console.log("subscription called*****************")
        subscriptionStatus();
        let timer = setInterval(() => subscriptionStatus(), 5 * 60 * 1000);
        
        return () => timer && clearInterval(timer);

      },[])
      // console.log(loading)
      if(loading) return <Loader />
      // else
  return (

    <ProfileDataContext.Provider value={{profileData, updateProfileData, nonPremiumUser,fetchAccountProfile , liteProfile}}>

      {/* {console.log("children",loading)} */}
        { callChildren && children}
    </ProfileDataContext.Provider>
  )
}

export default ProfileDataProvider
export const useProfileDataContext = ()=>{
  
    return useContext(ProfileDataContext)
}