import React, { useEffect, useState } from "react";

import FeaturedTournament from "./FeaturedTournament";
import AllGames from "./AllGames";

import JoinViaInviteCodeModal from "./JoinViaCode/JoinViaInviteCodeModal";
import { useSearchParams } from "react-router-dom";
import { useProfileDataContext } from "./ProfileContext";

const Main = ({ handleClickGame }) => {


const [showJVC, setShowJVC] = useState(false)
  const [searchParams] = useSearchParams()
  const { profileData, updateProfileData } = useProfileDataContext()


    // console.log(code.get('code'), "***********")
  useEffect(() => {
    if(!showJVC && searchParams.get('code')) {
      setShowJVC(true);
    }
  }, [])

  

  useEffect(() => {



    // fetch()
  }, []);
  return (
    <div class="content">
      <div className="content-inner">
      <div class="row gy-4">

       
        <FeaturedTournament />
        <AllGames handleClickGame={handleClickGame} />
  
        {
          showJVC && 
          <JoinViaInviteCodeModal showJVC={showJVC} setShowJVC={setShowJVC} code ={searchParams.get('code')} />
        }
        
        </div>
      </div>
    </div>
  );
};

export default Main;
