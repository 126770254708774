import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { json, useNavigate } from "react-router";

import { func } from "../../../Utilities/logFunc";
import { liveStreamingsList } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import { Link } from "react-router-dom";
import CompanyIcon from '../../../assets/new-images/User-Avatar.png'
import { useTranslation } from "react-i18next";
const PopulatVideos = () => {
  let [videoData, setVideoData] = useState([]);
  const navigate = useNavigate();
  const {t} = useTranslation()

  const fetchLiveStreamingsList = async (totalCount) => {
    try {
      let data = {
        skip: 0,
        limit: 4,
        filter: {
          isLive: false,
        },
      };

      const res = await liveStreamingsList(data);
      if (res?.data?.list) {
        setVideoData(res?.data?.list);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  useEffect(() => {
    fetchLiveStreamingsList();
  }, []);

  const videoUrls = (url) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);

    let videoId = match && match[2].length === 11 ? match[2] : null;

    return "//www.youtube.com/embed/" + videoId;
  };

  return (
    <>
      {videoData?.length > 0 && (
        <div class="col-12">
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <h2 class="h3 bodyBoldFont mb-0">{t('woe.popular_videos')}</h2>
            <Link
             to="/home/world-of-esports/popular-video"
              // onClick={() => navigate("/home/world-of-esports/popular-video")}
            >
              {t('woe.view_all')}
            </Link>
          </div>
          <Swiper
            className="swiper popularVideosSlider commonSlider"
            spaceBetween={20}
            modules={[Navigation, Pagination]}
            slidesPerView={2}
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            breakpoints={{
             
              768: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              },
            }}
            navigation
            pagination={{ clickable: true, el: ".swiper-pagination" }}
          >
            {videoData.length > 0 &&
              videoData.map((data, indp) => (
                <SwiperSlide class="swiper-slide" style={{height:'22.5rem'}}>
                  <div class="card popularVideoCard" style={{height:'100%', padding:'0.625rem'}}>
                    <div class="card-body" style={{height:'100%'}}>
                      <div class="card-img position-relative" style={{height:'85%'}}> 
                        <div class="card-video" style={{height:'100%'}}>
                          <iframe
                            class="rounded10"
                            width="100%"
                            height="100%"
                            src={videoUrls(data?.url)}
                            title={data?.name}
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen={true}
                          ></iframe>
                        </div>
                      </div>
                      <div class="card-content mt-2">
                        <div class="d-flex align-items-center">
                          <img
                          // style={{width: '10%'}}
                            class="img-thumbnail-36"
                            src={data?.logo?.default ? url.imageUrl + data?.logo?.default :  CompanyIcon}
                            alt=""
                          />
                          <div class="info ms-2 ps-1 pt-2">
                            <h6 class="mb-1 bodyBoldFont fs-4">{data?.name.length > 25 ? <marquee>{data?.name}</marquee> : data?.name}</h6>
                            {/* <h6 class="mb-0 fs-6 bodyNormalFont text-body">
                              D11 Gaming
                            </h6> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            <div class="swiper-pagination"></div>
          </Swiper>
        </div>
      )}
    </>
  );
};

export default PopulatVideos;
