import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { url } from "../../../Utilities/url";
import { accountCheckEmail, sendOTP, validateOtp } from "../../../Utilities/controller";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";
import { logout } from "../../../Utilities/logout";
import { errors } from "../../../Utilities/error";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";

const EmailVerificationModal = ({
  emailVerificationModal,
  setEmailVerificationModal,
  sendRequest,
  tagMobile,
  tagEmail
}) => {
  const [show, setShow] = useState(emailVerificationModal);
  const { fetchAccountProfile,liteProfile } = useProfileDataContext()
  const { t } = useTranslation();
  const [timerCount, setTimer] = useState(30);

  const handleClose = () => {
    setEmailVerificationModal(false);
    setShow(false);
    sendRequest(false);
  };

  // This function should set the OTP Timer of 30 seconds.
  const setCountDownTimer = () => {
    setTimer(timerCount - 1);
  };

  // This function should show/hide Resend OTP Button Visibility.
  const showHideResendOTP = () => {
    setTimer(30);
  };

  useEffect(() => {
    if (timerCount !== 0) {
      setTimeout(() => {
        setCountDownTimer();
      }, 1000);
    }
  }, [timerCount]);

  const handleSubmit = async (values) => {
    try {
      if (frmInitialValues?.type === "otpRequest") {
        if(!tagMobile && tagEmail){
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values?.email);
        if (
          values?.email != "" &&
          values?.email != "null" &&
          values?.email != "undefined" &&
          values?.email != null &&
          values?.email != undefined && isValidEmail
        ) {
            let data = {
              type: "otpRequest",
              email: values?.email,
            };

            const res = await accountCheckEmail(data);

          // var payload = {
          //   phone: this.state.phone,
          //   phoneCode: this.state.phoneCode,
          // };

          // const res1 = await sendOTP(payload);
          if (res?.data?.success) {
            let fromdataobj = { ...frmInitialValues };
            fromdataobj.isSubmitEmail = true;
            fromdataobj.type = "validateOTP";
            setFrmInitialValues(fromdataobj);
          } else {
            toast.error(res?.response?.data?.errors?.[0]?.msg);
          }
        } 
        else {
          
          toast.error(t('error_Please_enter_a_valid_email_address'));

        }
      }
      else{
         if (
          values?.mobile != "" &&
          values?.mobile != "null" &&
          values?.mobile != "undefined" &&
          values?.mobile != null &&
          values?.mobile != undefined
        ) {
          console.log(values?.mobile)
          var payload = {
            type: "otpRequest",
            phone: values?.mobile,
            phoneCode:liteProfile?.country?.dialingCode ,
            // country: liteProfile?.country?._id
          };
          const res = await sendOTP(payload);
          // console.log(res,"************coin")
          if (res?.data?.success) {
            let fromdataobj = { ...frmInitialValues };
            fromdataobj.isSubmitMobile = true;
            fromdataobj.type = "validateOTP";
            setFrmInitialValues(fromdataobj);
          } else {
            toast.error(res?.response?.data?.errors?.[0]?.msg);
          }
        } 
        else {
          
          toast.error(t('error_enter_valid_phone'));

        }
      }
      } 
      else {
        if (
          values?.otp != "" &&
          values?.otp != "null" &&
          values?.otp != "undefined" &&
          values?.otp != null &&
          values?.otp != undefined
        ) {
          if(!tagMobile && tagEmail){
          let data = {
            type: "validateOTP",
            email: values?.email,
            otp: values?.otp,
          };

          const res = await accountCheckEmail(data);

          if (res?.data?.success) {
            sendRequest(true);
            setEmailVerificationModal(false);
            fetchAccountProfile()
            setShow(false);
          } else {
            toast.error(res?.data?.errors?.[0]?.msg);
          }
        } 
      
      else{
        let data = {
          phoneCode: liteProfile?.country?.dialingCode,
          phone: values?.mobile,
          otp: values?.otp,
        };
       

        const res = await validateOtp(data);

        if (res?.data?.success) {
          sendRequest(true);
          setEmailVerificationModal(false);
          fetchAccountProfile()
          setShow(false);
        } else {
          toast.error(res?.data?.errors?.[0]?.msg);
        }
      } 
    }
      else {
        toast.error(t('error_enter_code'));
      }
      
      }
    } catch (error) {
      // if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
      // logout()
      setEmailVerificationModal(false);
      sendRequest(false);
      setShow(false);
      toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  // This function should call the Resend OTP API
  const callToResendOTPAPI = async () => {
    if(!tagMobile && tagEmail) {
      if (
        frmInitialValues?.email != "" &&
        frmInitialValues?.email != "null" &&
        frmInitialValues?.email != "undefined" &&
        frmInitialValues?.email != null &&
        frmInitialValues?.email != undefined
      ) {
        let data = {
          type: "otpRequest",
          email: frmInitialValues?.email,
        };

        const res = await accountCheckEmail(data);
        if (res?.data?.success) {
          let fromdataobj = { ...frmInitialValues };
          fromdataobj.isSubmitEmail = true;
          fromdataobj.type = "validateOTP";
          setFrmInitialValues(fromdataobj);
          setTimer(30);
        } else {
          toast.error(res?.data?.errors?.[0]?.msg);
        }
      }
      else {
        let fromdataobj = { ...frmInitialValues };
        fromdataobj.isSubmitEmail = false;
        fromdataobj.type = "otpRequest";
        setFrmInitialValues(fromdataobj);
        toast.error(t('error_Please_enter_a_valid_email_address'));
      }
    }
    else {
      if (
        frmInitialValues?.mobile != "" &&
        frmInitialValues?.mobile != "null" &&
        frmInitialValues?.mobile != "undefined" &&
        frmInitialValues?.mobile != null &&
        frmInitialValues?.mobile != undefined
      ) {
        var payload = {
          type: "otpRequest",
          phone: frmInitialValues?.mobile,
          phoneCode:liteProfile?.country?.dialingCode ,
          // country: liteProfile?.country?._id
        };
        const res = await sendOTP(payload);
        if (res?.data?.success) {
          let fromdataobj = { ...frmInitialValues };
          fromdataobj.isSubmitMobile = true;
          fromdataobj.type = "validateOTP";
          setFrmInitialValues(fromdataobj);
          setTimer(30);
        } else {
          toast.error(res?.data?.errors?.[0]?.msg);
        }
      }
      else {
        let fromdataobj = { ...frmInitialValues };
        fromdataobj.isSubmitMobile = false;
        fromdataobj.type = "otpRequest";
        setFrmInitialValues(fromdataobj);
      }
    }
  };

  const [frmInitialValues, setFrmInitialValues] = useState({
    email: liteProfile?.item?.email || '',
    isSubmitEmail: false,
    mobile:liteProfile?.item?.phone || '',
    isSubmitMobile: false,
    otp: "",
    type: "otpRequest",
  });

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade "
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <Formik
          enableReinitialize={true}
          initialValues={frmInitialValues}
          //   validationSchema={applyPromoCodeSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({
            // handleSubmit,
            errors,
            values,
            // setFieldValue,
            // validateField,
            // validateForm,
          }) => (
            <Form>
              <div
                class="col-12 justify-content-center align-items-center"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h3 class="bodyBoldFont mb-5 text-center text-dark">
                  {tagMobile && !tagEmail? 
                 t('reward_store.mobile_verification') : t('reward_store.email_verification')}
                </h3>

                {frmInitialValues?.isSubmitEmail && (
                  <span className="mb-5 text-dark">
                    {t('reward_store.we_have_sent_an_OTP_on_your_email')}{" "}
                    <span className="bodyBoldFont">
                      {frmInitialValues?.email}
                    </span>
                  </span>
                )}
                {frmInitialValues?.isSubmitMobile && (
                  <span className="mb-5 text-dark">
                    {t('reward_store.we_have_sent_an_OTP_on_your_mobile')}{" "}
                    <span className="bodyBoldFont">
                      {frmInitialValues?.mobile}
                    </span>
                  </span>
                )}
                {console.log(tagMobile)}
{
  tagMobile && !tagEmail ? 
  <div className="col-6 mb-5 text-dark">
  {!frmInitialValues?.isSubmitMobile ? (
    <>
      <div className={`row`}>
        <div className="col-4">
          <label
            for="inputCoutryCode"
            className="form-label text-nowrap "
          >
          {t('Profile.country_code')}
          </label>
          {/* <select
                                      id="inputCoutryCode"
                                      className="form-select select d11-phoneCode"
                                      name='phoneCode'
                                      value={state?.phoneCode}
                                      disabled={state?.phoneCode}
                                
                                  >
                                      <option>{state?.phoneCode}</option>

                                  </select> */}
          <div
            class={`input-group w-auto d11-phoneCode form-select select d11-phone-code`}
            style={{ backgroundColor: "var(--secondary-color-emphasis)", border:'none' ,color: "var(--color-black)", opacity: "1" }}
          >
            <img
              className="icon-24"
              style={{ borderRadius: "50%" }}
              src={
                url.imageUrl +
                liteProfile?.country?.flag?.default
              }
              alt=""
            />

            <div
              name=""
              id=""
              style={{
                maxWidth: "50px",
                alignItems: "center",
                padding: " 0 5px",
                display: "flex",
                alignItems: "center",
              }}
              disabled={true}
            >
              {liteProfile?.country?.dialingCode}
            </div>
          </div>
        </div>
      
      <div className="col-8">
      <label for="mobile" className="form-label">
      {t('login.mobile_number')}
      </label>
      <Field
        type="number"
        className="form-control"
        id="mobile"
        placeholder={t('login.enter_mobile_number')}
        name="mobile"
        onChange={(e) => {
          let fromdataobj = { ...frmInitialValues };
          fromdataobj.mobile = e.target.value;
          setFrmInitialValues(fromdataobj);
        }}
      />
      </div>
      </div>
      {errors.mobile ? <div>{errors.mobile}</div> : null}
    </>
  ) : (
    <>
      <label for="otp" className="form-label">
      {t('basic_profile.verification_code')}
      </label>
      <Field
        type="number"
        className="form-control"
        id="otp"
        placeholder= {t('login.enter_verification_code')}
        name="otp"
        onKeyDown={(e) =>
          ["e", "E", "+", "-","."].includes(e.key) &&
          e.preventDefault()
        }
        onChange={(e) => {
          let fromdataobj = { ...frmInitialValues };
          fromdataobj.otp = e.target.value;
          setFrmInitialValues(fromdataobj);
        }}
        maxLength={6}
       
      />
      {errors.otp ? <div>{errors.otp}</div> : null}
    </>
  )}
</div>
:
<div className="col-6 mb-5 text-dark">
                  {!frmInitialValues?.isSubmitEmail ? (
                    <>
                      <label for="email" className="form-label">
                      {t('reward_store.email')}
                      </label>
                      <Field
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder={t('reward_store.enter_email')}
                        name="email"
                        onChange={(e) => {
                          let fromdataobj = { ...frmInitialValues };
                          fromdataobj.email = e.target.value;
                          setFrmInitialValues(fromdataobj);
                        }}
                      />
                      {errors.email ? <div>{errors.email}</div> : null}
                    </>
                  ) : (
                    <>
                      <label for="otp" className="form-label">
                      {t('basic_profile.verification_code')}
                      </label>
                      <Field
                        type="text"
                        className="form-control"
                        id="otp"
                        placeholder= {t('login.enter_verification_code')}
                        name="otp"
                        onChange={(e) => {
                          let fromdataobj = { ...frmInitialValues };
                          fromdataobj.otp = e.target.value;
                          setFrmInitialValues(fromdataobj);
                        }}
                        maxLength={6}
                        onKeyPress={(e) => {
                          if (new RegExp(/[0-9]/).test(e.key)) {
                          } else e.preventDefault();
                        }}
                      />
                      {errors.otp ? <div>{errors.otp}</div> : null}
                    </>
                  )}
                </div> 
                }

              

                <div className="col-6">
                  {frmInitialValues?.isSubmitEmail ?
                      <button
                        className="btn btn-primary btn-arrow"
                        style={{ width: "100%" }}
                        // disabled={timerCount == 0 ? true : false}
                      >
                        {t('crm.submit')}
                      </button>
                      :
                      frmInitialValues?.isSubmitMobile ?
                        <button
                          className="btn btn-primary btn-arrow"
                          style={{ width: "100%" }}
                          // disabled={timerCount == 0 ? true : false}
                        >
                          {t('crm.submit')}
                        </button>
                        :
                      <button
                        className="btn btn-primary btn-arrow"
                        style={{ width: "100%" }}
                      >
                        {t('crm.submit')}
                      </button> 
                  }
                  {frmInitialValues?.isSubmitEmail &&
                    <>
                      <div class="links">
                        <h5 className="d11-text-center mt-3">
                          <span class="link-offset-2 link-underline link-underline-opacity-0 verification-link d11-otp-resend">
                            {" "}
                            {t("login.didnt_receive_code")}
                          </span>
                        </h5>
                      </div>
                      <div class="links">
                        <h5 className="d11-text-center">
                          {timerCount == 0 ? (
                            <span
                              class="link-offset-2 link-underline link-underline-opacity-0 verification-link"
                              style={{
                                color: "var(--primary-color-red)",
                                cursor: "pointer",
                                textDecoration: 'underline !important'
                              }}
                              onClick={(e) => callToResendOTPAPI(e)}
                            >
                              <b>{t("login.resend")}</b>
                            </span>
                          ) : null}
                        </h5>
                        <h5 className="d11-text-center">
                          {timerCount != 0 ? (
                            <span
                              class="link-offset-2 link-underline link-underline-opacity-0 verification-link d11-otp-resend d11-otp-resend-timer"
                              onClick={(e) => callToResendOTPAPI(e)}
                            >
                              {t("login.request_one", { n: timerCount })}
                            </span>
                          ) : null}
                        </h5>
                      </div>
                    </>
                  }
                  {frmInitialValues?.isSubmitMobile &&
                    <>
                      <div class="links">
                        <h5 className="d11-text-center mt-3">
                          <span class="link-offset-2 link-underline link-underline-opacity-0 verification-link d11-otp-resend">
                            {" "}
                            {t("login.didnt_receive_code")}
                          </span>
                        </h5>
                      </div>
                      <div class="links">
                        <h5 className="d11-text-center">
                          {timerCount == 0 ? (
                            <span
                              class="link-offset-2 link-underline link-underline-opacity-0 verification-link"
                              style={{
                                color: "var(--primary-color-red)",
                                cursor: "pointer",
                                textDecoration: 'underline !important'
                              }}
                              onClick={(e) => callToResendOTPAPI(e)}
                            >
                              <b>{t("login.resend")}</b>
                            </span>
                          ) : null}
                        </h5>
                        <h5 className="d11-text-center">
                          {timerCount != 0 ? (
                            <span
                              class="link-offset-2 link-underline link-underline-opacity-0 verification-link d11-otp-resend d11-otp-resend-timer"
                              onClick={(e) => callToResendOTPAPI(e)}
                            >
                              {t("login.request_one", { n: timerCount })}
                            </span>
                          ) : null}
                        </h5>
                      </div>
                    </>
                  }
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default EmailVerificationModal;
