import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { func } from "../../../Utilities/logFunc";
import { getContestByCode, html5GamesUpdateView } from "../../../Utilities/controller";
import moment from "moment";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import { useTranslation } from "react-i18next";
import D11Gold from '../../../assets/images/premium_logo.png'

const JoinFreeGamesModal = ({
  selectedGame,
  selectedSubGame,
  showJoinModal,
  setShowJoinModal,
  setAllowToViewGame,
  setJoinId
}) => {
  const [show, setShow] = useState(showJoinModal);
  let premiumUser = JSON.parse(localStorage.getItem("premiumUser"))
  const { t } = useTranslation()
  const handleClose = () => {
    setShowJoinModal((prev) => false);
    setShow(false);
  };

   
  const  handlerJoin = async ()   =>{
    var data = {
      html5Category: selectedGame?._id,
      htmlGame: selectedSubGame._id,
      status: "start"
    }
    var response = await html5GamesUpdateView(data);
    if (response?.data?.success) {
      setJoinId(response?.data?.item?._id)
      setAllowToViewGame(true);
      handleClose();
    }
    else {
      setAllowToViewGame(false);
      handleClose();
    }
    
  }
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className={premiumUser ? "premiumUserTournamentModal tournamentModal modal fade" : "tournamentModal modal fade "}

      centered
    >
      <Modal.Body className={`${premiumUser ? "bg-premium text-light" : ""}`}>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class={`row justify-content-center ${premiumUser ? "bg-premium text-light" : ""}`}>
          <div class="col-11 px-lg-4">
            {
              premiumUser ?
                <div className="text-center">
                  <img style={{ width: '7.5rem', height: '7.5rem' }} src={D11Gold} />
                  {/* <img  style={{width:'7.5rem', height:'7.5rem'}} src={localStorage.getItem("planName") == "Grandmaster" ? D11Gold : Champion} />  */}

                  <h2 className='d11-premium mt-2  mb-4'>{t("tournaments.premium")}</h2>
                </div>
                : ""

            }
            <div class="title">
              <h3 class={premiumUser ? "text-center bodyBoldFont mb-4 pb-3 text-white" : "text-center bodyBoldFont mb-4 pb-3 text-dark"}>
               {selectedGame?.name}
              </h3>
            </div>
            <div class=" text-center">
              <div class="d-flex justify-content-between fw-normal">
                <h4 class={`mb-3 fw-normal ${premiumUser ? "text-light" : "text-dark"}`}>
                 {selectedSubGame?.name}
                </h4>
                <h4 class={`mb-3 fw-normal ${premiumUser ? "text-light" : "text-dark"}`}>
                  <span>

                  </span>
                  <span>
                    {" "}

                  </span>
                </h4>
              </div>
              <div class="bg-primary-color-red text-white p-4 text-start">
                <h3 class="mb-2 bodyBoldFont">{t("contest.confirmation")}</h3>
                <h4 class="mb-0 d-flex align-items-center text-white">


                </h4>
              </div>
              <div class="d-flex flex-column text-start mt-4 pt-2">
                <ul class="list-group list-group-flush">
                  <li class="list-group-item d-flex justify-content-between px-0 py-3">
                    <h4 class={`bodyBoldFont mb-0 ${premiumUser ? "text-light" : ""}`}>{t("contest.entry_fee")}</h4>
                    <h4 class={` mb-0 ${premiumUser ? "text-light" : ""}`}>
                      {/* {tournaments?.entryFee > 0 && !premiumUser ? tournaments?.entryFee : t("tournaments.free")} */}
                      {t("tournaments.free")}
                    </h4>
                  </li>
                </ul>
              </div>
              <div class="row justify-content-center mt-2 pt-2 fw-normal text-dark">
                  <p>{t("free_game.enjoy_free_games_on_gamerji")}</p>
                </div>
              <div class="row justify-content-center mt-4 pt-2">
                <div class="col-lg-7">
                  <div class="d-grid">
                    <button
                      class={premiumUser ? "btn btn-golden" : "btn btn-primary btn-arrow"}
                      onClick={handlerJoin}
                    >
                      {t("contest.join_contest")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default JoinFreeGamesModal;
