import React, { useState, useEffect } from "react";
import Logo from "../../assets/images/powerplay.png";
import Coin from "../../assets/images/coin-icon-svg.svg";
import Diamond from "../../assets/images/diamond-icon.png";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../Utilities/routesFb";
import { func } from "../../Utilities/logFunc";
import { getAccountProfile, getLanguage } from "../../Utilities/controller";
import JoinViaInviteCodeModal from "./JoinViaCode/JoinViaInviteCodeModal";
import ApplyPromoCodeModel from "./ApplyPromoCode/ApplyPromoCodeModel";
import SuccessPromoCodeModal from "./ApplyPromoCode/SuccessPromoCodeModal";
import TournamentConfirmationModal from "./Tournament/TournamentConfirmationModal";
import TournamentModal from "./Tournament/TournamentModal";
import WinningBreakUp from "./Tournament/WinningBreakUp";
import JoinModal from "./Tournament/JoinModal";
import Logout from "../Auth/Logout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GamerjiPoints from "./GamerjiPoints/GamerjiPoints";

import { url as URL } from "../../Utilities/url";

import { useProfileDataContext } from "./ProfileContext";
import { logout } from "../../Utilities/logout";
import i18next from "i18next";
import { t } from "i18next";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
const Navbar = ({ activeNav, isActiveMenu, seActiveMenu }) => {
  const navigate = useNavigate();
  const {t} = useTranslation()
  const navsv = [
    "/home",
    "/home/world-of-esports",
    "/home/coin-store",
    "/home/reward-store",
    "/home/leader-board",
    "/home/customer-care",
    "/home/getPremium",
  ];
  const navName = [
    "sidebar.all_games",
    "sidebar.world_of_esports",
    "sidebar.coin_store",
    "sidebar.reward_store",
    "sidebar.leaderboard",
    "sidebar.customer_care",
    "sidebar.explore_premium",
  ];

  const [url, setUrl] = useState(window.location.href.split("/").length - 3);
  // const [profileData, setProfileData] = useState(null);
  const [showJVC, setShowJVC] = useState(false);
  const [showAPC, setShowAPC] = useState(false);
  const [showSPC, setShowSPC] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [showWinningModal, setShowWinningModal] = useState(false);
  const [showTournamentModal, setShowTournamentModal] = useState(false);
  const [showTournamentConfirmationModal, setShowTournamentConfirmationModal] =
    useState(false);
  const [showReferFriend, setShowReferFriend] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [showGamerjiPointsModal, setShowGamerjiPointsMOdal] = useState(false);
  const [showFavoriteGame, setShowFavoriteGame] = useState(false);
  const [showMyProfile, setShowMyProfile] = useState(false);
  const [showDLR, setShowDLR] = useState(false);
  const [active, setActive] = useState(null);
  const { profileData, updateProfileData } = useProfileDataContext();
  // var user_data = JSON.parse(localStorage.getItem("profile"))?.item;

  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("JZLang")  || Cookies.get("JZLang") ||  "English"
  );


  const currentLang = localStorage.getItem("JZLangCode") || Cookies.get("JZLangCode")  ||  "en"

  const fetchLanguage = async () => {
    const body = {
      companyId: process.env.REACT_APP_COMPANY_ID,
    };
    try {
      const res = await getLanguage(body);
      // console.log("fetchLanguage",res?.data?.data)
      setLanguages(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // fetchLanguage();
    const linkTag = document.getElementById('bootstrap_css');
    document.documentElement.setAttribute('lang', currentLang.toLowerCase());
    document.documentElement.dir =
      currentLang.toLowerCase() == "ar" ? "rtl" : "ltr";
    if (currentLang.toLowerCase() == "ar") {
      
      linkTag?.setAttribute(
        'href',
        `${process.env.REACT_APP_CLIENT_BASE_URL}assets/css/bootstrap.rtl.min.css`
      );
    }
         
    document.title = t("title");
    // document.title = "Gamerji Gaming Portal";
  }, [currentLang]);

  const handleApplyPromoCode = () => {
    eventTracking(events.APPLY_PROMO_CODE);
    setShowAPC(true);
  };

  const getTotalCoin = () => {
    let index = profileData?.item?.wallet?.findIndex((obj) => obj.currencyData?.code == process.env.REACT_APP_IN_CURRENCY_CODE);

    if (index > -1) {
      return profileData?.item?.wallet[index].winningAmount ? profileData?.item?.wallet[index].winningAmount : 0;
    }
    return 0;
  };



  const handlerJVC = () => {
    func("JVC", "called");
    eventTracking(events.JOIN_VIA_INVITE_CODE);
    setShowJVC(true);
  };

  const handlerJoin = () => {
    func("clikced");
    setShowJoinModal(true);
  };

  const handlerJoinNow = () => {
    func("handlerjoin");
    setShowJoinModal(false);
    setShowTournamentModal(true);
  };

  const handlerWinning = () => {
    func("clicked");
    setShowWinningModal((prev) => true);
  };

  useEffect(() => {
    func("active nav", activeNav);
    let index = null;
    navsv.forEach((element, ind) => {
      if (element == activeNav) index = ind;
    });
    func("index 126", index);
    // getAccountProfileDetails();
  }, []);

  useEffect(() => {
    // alert(showJVC);
  }, [showJVC]);

  const getTotalDiamond = () => {
    // let index =
    //   profileData?.item &&
    //   profileData?.item?.wallet &&
    //   profileData?.item?.wallet.findIndex(
    //     (obj) => obj.currencyData?.code == "diamond"
    //   );
    // // let index = accountResponse.wallet.findIndex(obj => obj.currencyData.name == 'diamond')
    // if (index > -1) {
    //   return profileData?.item?.wallet[index].winningAmount != null
    //     ? profileData?.item?.wallet[index].winningAmount
    //     : 0;
    // }
    // return 0;
    let index = profileData?.item?.wallet?.findIndex((obj) => obj.currencyData?.code == process.env.REACT_APP_OUT_CURRENCY_CODE);

    if (index > -1) {
    
      return profileData?.item?.wallet[index].winningAmount ? profileData?.item?.wallet[index].winningAmount : 0;
    }
    return 0;
  };
  
  return (
    <>
      <ToastContainer   position="top-center" autoClose={2000} />
      <nav class="navbar navbar-top fixed-top navbar-expand">
        <div class="collapse navbar-collapse justify-content-between">
          <div class="navbar-logo">
            <button
              class={`navbar-toggler mainMenuToggler ${isActiveMenu? "collapsed": ""}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded={!isActiveMenu? "false": "true"}
              aria-label="Toggle navigation"
              onClick={()=> seActiveMenu(!isActiveMenu)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
            <Link class="navbar-brand" to="/home">
              <img src={Logo} alt="" />
            </Link>
          </div>
          <div class="navRight d-flex align-items-center justify-content-sm-between justify-content-end">
            <nav aria-label="breadcrumb" className="d-none d-sm-block">
              <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item">
                  {/* {console.log("navsv", navName[activeNav])} */}
                  <Link to={navsv[activeNav]}>{t(navName[activeNav])}</Link>
                </li>
                {/* <li class="breadcrumb-item"></li> */}
                {window.location.pathname.split("/")[
                  window.location.pathname.split("/").length - 1
                ] === "my-profile" ? (
                  <>
                    <li class="breadcrumb-item" aria-current="page">
                      <Link to="/home/my-profile">
                        {/* {t("header.my_profile")} */}
                        
                        {
                              t(
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ])
                            }
                      </Link>
                    </li>
                  </>
                ): window.location.pathname.split("/")[
                  window.location.pathname.split("/").length - 1
                ] === "legality" ? (
                  <>
                    <li class="breadcrumb-item" aria-current="page">
                      <Link to="/home/legality">
                        {console.log(t(
                          "right_hand_drop_down."+
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ]))}
                        
                        {
                              t("right_hand_drop_down."+
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ])
                            }
                      </Link>
                    </li>
                  </>
                )
                : window.location.pathname.split("/")[
                    window.location.pathname.split("/").length - 2
                  ] === "user-profile" ? (
                  <>
                    <li class="breadcrumb-item" aria-current="page">
                      <Link>{t("header.user_profile")}</Link>
                    </li>
                  </>
                ) : window.location.pathname.split("/")[
                    window.location.pathname.split("/").length - 1
                  ] === "free-games" ? (
                  <>
                    <li class="breadcrumb-item" aria-current="page">
                      <Link to="/home/free-games">
                        {t("header.free_games")}
                      </Link>
                    </li>
                  </>
                ) : activeNav == 0 &&
                  window.location.pathname.split("/").length > 2 ? (
                  <>
                    <li class="breadcrumb-item" aria-current="page">
                      <Link to="/home/tournaments">
                        {localStorage.getItem("activeGame")}
                      </Link>
                    </li>
                    {window.location.pathname.split("/")[
                      window.location.pathname.split("/").length - 1
                    ] == "tournaments" ? (
                      <li
                        // class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        {/* <Link to={window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}> */}
                        {/* {
                          window.location.pathname.split("/")[
                            window.location.pathname.split("/").length - 1
                          ]
                        } */}
                        {/* </Link> */}
                      </li>
                    ) : window.location.pathname.split("/")[
                        window.location.pathname.split("/").length - 1
                      ] == "private-contest" ? (
                      <>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Link to="/home/private-contest">
                            {/* {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[0]
                            }{" "}
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[1]
                            } */}
                            {t(window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ])}
                          </Link>
                        </li>
                      </>
                    ) : // window.location.pathname.split("/")[
                    //   window.location.pathname.split("/").length - 2
                    // ] == "private-contest" ?
                    //  (
                    //   <>
                    //     <li
                    //       class="breadcrumb-item"
                    //       aria-current="page"
                    //       style={{ textTransform: "capitalize" }}
                    //     >
                    //       <Link to="/home/private-contest">
                    //         {
                    //           window.location.pathname
                    //             .split("/")
                    //           [
                    //             window.location.pathname.split("/").length - 2
                    //           ].split("-")[0]
                    //         }{" "}
                    //         {
                    //           window.location.pathname
                    //             .split("/")
                    //           [
                    //             window.location.pathname.split("/").length - 2
                    //           ].split("-")[1]
                    //         }
                    //       </Link>
                    //     </li>
                    //     <li
                    //       class="breadcrumb-item"
                    //       aria-current="page"
                    //       style={{ textTransform: "capitalize" }}
                    //     >
                    //       {
                    //         window.location.pathname
                    //           .split("/")
                    //         [
                    //           window.location.pathname.split("/").length - 1
                    //         ].split("-")[0]
                    //       }{" "}
                    //       {
                    //         window.location.pathname
                    //           .split("/")
                    //         [
                    //           window.location.pathname.split("/").length - 1
                    //         ].split("-")[1]
                    //       }
                    //     </li>
                    //   </>
                    // )
                    // :
                    window.location.pathname.split("/").length > 3 &&
                      window.location.pathname.split("/")[
                        window.location.pathname.split("/").length - 2
                      ] == "manage-contest" ? (
                      <>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Link to="/home/private-contest">
                            {/* {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 3
                                ].split("-")[0]
                            }{" "}
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 3
                                ].split("-")[1]
                            } */}
                            {t(window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 3
                                ])}
                          </Link>
                        </li>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          style={{ textTransform: "capitalize" }}
                        >
                          {/* {
                            window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 2
                              ].split("-")[0]
                          }{" "}
                          {
                            window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 2
                              ].split("-")[1]
                          } */}
                            {t(window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 2
                                ])}
                        </li>
                      </>
                    ) :
                    
                      
                    window.location.pathname.split("/").length > 3 &&
                    window.location.pathname.split("/")[
                      window.location.pathname.split("/").length - 1
                    ] == "create-contest" ? (
                    <>
                   {/* { console.log(  window.location.pathname.split("/"))} */}
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        <Link to="/home/private-contest">
                          {/* {
                            window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 3
                              ].split("-")[0]
                          }{" "}
                          {
                            window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 3
                              ].split("-")[1]
                          } */}
                        
                          {t(window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 2
                              ])}
                        </Link>
                      </li>
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        {/* {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 2
                            ].split("-")[0]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 2
                            ].split("-")[1]
                        } */}
                         {/* {console.log(t(window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 1
                              ]))} */}
                          {t(window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 1
                              ])}
                      </li>
                    </>
                  ) :

                    window.location.pathname.split("/").length > 3 &&
                    window.location.pathname.split("/")[
                      window.location.pathname.split("/").length - 2
                    ] == "edit-contest" ? (
                    <>
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        <Link to="/home/private-contest">
                          {/* {
                            window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 3
                              ].split("-")[0]
                          }{" "}
                          {
                            window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 3
                              ].split("-")[1]
                          } */}
                          {t(window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 3
                              ])}
                        </Link>
                      </li>
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        {/* {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 2
                            ].split("-")[0]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 2
                            ].split("-")[1]
                        } */}
                          {t(window.location.pathname
                              .split("/")
                              [
                                window.location.pathname.split("/").length - 2
                              ])}
                      </li>
                    </>
                  ) :
                    window.location.pathname.split("/")[
                        window.location.pathname.split("/").length - 1
                      ] == "my-contest" ? (
                      <>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Link >
                            {/* {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[0]
                            }{" "} */}
                            {
                              t(
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ])
                            }
                          </Link>
                        </li>
                      </>
                    ) : window.location.pathname.split("/")[
                        window.location.pathname.split("/").length - 1
                      ] == "payment-status" ? (
                      <>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Link to="">
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[0]
                            }{" "}
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[1]
                            }
                          </Link>
                        </li>
                      </>
                    ) : window.location.pathname.split("/")[
                        window.location.pathname.split("/").length - 1
                      ] == "payment-status-subscription" ? (
                      <>
                        <li
                          class="breadcrumb-item"
                          aria-current="page"
                          style={{ textTransform: "capitalize" }}
                        >
                          <Link to="">
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[0]
                            }{" "}
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[1]
                            }
                            {
                              window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ].split("-")[2]
                            }
                          </Link>
                        </li>
                      </>
                    ) : (
                      <li class="breadcrumb-item" aria-current="page">
                        {window.location.pathname.split("/")[
                          window.location.pathname.split("/").length - 2
                        ] == "tournament-details"
                          ? t('tournament-details')
                          : window.location.pathname.split("/")[
                              window.location.pathname.split("/").length - 2
                            ] == "contest-details"
                          ? t('contest-details')
                          : window.location.pathname.split("/")[
                              window.location.pathname.split("/").length - 2
                            ] == "scrim-details"
                          ? t('contest-details')
                          : ""}
                      </li>
                    )}
                  </>
                ) : activeNav == 1 &&
                  window.location.pathname.split("/").length > 3 ? (
                  <>
                    {
                      // window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] == 'tournaments'
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        {/* <Link to={window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}> */}
                        {/* {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[0]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[1]
                        } */}
                        {console.log(t(window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ]))}
                        {t(window.location.pathname
                                .split("/")
                                [
                                  window.location.pathname.split("/").length - 1
                                ])}
                        {/* </Link> */}
                      </li>
                    }
                  </>
                ) : activeNav == 5 &&
                  window.location.pathname.split("/").length > 3 ? (
                  <>
                    {window.location.pathname.split("/")[
                      window.location.pathname.split("/").length - 1
                    ] == "raise-a-complaint" ? (
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        {/* <Link to={window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}> */}
                        {/* {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[0]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[1]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[2]
                        } */}
                        {t( window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ])}
                        {/* </Link> */}
                      </li>
                    ) : (
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        {/* <Link to={window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}> */}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 2
                            ].split("-")[0]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 2
                            ].split("-")[1]
                        }{" "}
                      </li>
                    )}
                  </>
                ) : activeNav == 6 &&
                  window.location.pathname.split("/").length > 3 ? (
                  <>
                    {window.location.pathname.split("/")[
                      window.location.pathname.split("/").length - 1
                    ] == "get-premium" ? (
                      <li
                        class="breadcrumb-item"
                        aria-current="page"
                        style={{ textTransform: "capitalize" }}
                      >
                        {/* <Link to={window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}> */}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[0]
                        }{" "}
                        {
                          window.location.pathname
                            .split("/")
                            [
                              window.location.pathname.split("/").length - 1
                            ].split("-")[1]
                        }{" "}
                      </li>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                {/* <li class="breadcrumb-item" aria-current="page">
                  <a href="#">{gameName}</a>
                </li> */}
                {/* <li class="breadcrumb-item active" aria-current="page">
                Tournaments
              </li> */}
              </ol>
            </nav>
            <div class="rightNav">
              {/* <div class="badge">
                <span class="badge-icon" style={{caretColor: 'transparent'}}>
                 
                  <img src={URL.imageUrl + profileData?.item?.wallet[profileData?.item?.wallet?.findIndex((obj) => obj.currencyData?.code == process.env.REACT_APP_IN_CURRENCY_CODE)]?.currencyData?.img?.default} alt="" className="icon-30"/>
                </span>
                
                {getTotalCoin()}
              </div> */}
              <div class="badge">
                <span class="badge-icon" style={{caretColor: 'transparent'}}>
                  <img src={URL.imageUrl + profileData?.item?.wallet[profileData?.item?.wallet?.findIndex((obj) => obj.currencyData?.code == process.env.REACT_APP_OUT_CURRENCY_CODE)]?.currencyData?.img?.default} alt="" className="icon-30"/>
                </span>
                {/* {localStorage.getItem("currentD11")}{" "} */}
                {getTotalDiamond()}
              </div>
              {/* <div class="badge">
                <span class="badge-icon">
                  <img src={Diamond} alt="" />
                </span>
                {/* {localStorage.getItem("currentD11")}{" "} */}
              {/* {getTotalDiamond()} */}

              {/* {getTotalDiamond()} */}
              {/* </div> */}
            {/* <div class="dropdown customDropdown ms-xl-5 ms-3">
              <span
                class="dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {selectedLanguage}
              </span>

              <ul class="dropdown-menu">
                {languages?.map((ele,ind) => (
                  <li key={ind}>
                    <span
                      class="dropdown-item"
                      onClick={(e) => {
                        setLoader(true);
                        i18next.changeLanguage(ele?.code?.toLowerCase());
                        localStorage.setItem(
                          "JZLang",
                          ele.name
                        );
                        localStorage.setItem('JZLangCode',ele?.code?.toLowerCase())
                        Cookies.set('JZLang',ele.name)
                        Cookies.set('JZLangCode',ele?.code?.toLowerCase())
                        eventTracking(events.CHANGE_LANGUAGE,{
                          GJ_LanguageName: ele?.code?.toLowerCase()
                        });
                        // const head = document.head;
                        // const link = document.createElement("link");
                        // link.type = "text/css";
                        // link.rel = "stylesheet";

                        // // Set the href based on the language
                        // link.href =
                        //   currentLang === "ar"
                        //     ? `"%PUBLIC_URL%/assets/css/bootstrap.rtl.min.css`
                        //     : `"%PUBLIC_URL%/assets/css/bootstrap.min.css`;

                        // // Clear existing stylesheets, if any
                        // const existingLinks = head.querySelectorAll(
                        //   'link[data-type="bootstrap"]'
                        // );
                        // existingLinks.forEach((existingLink) =>
                        //   existingLink.remove()
                        // );

                        // // Add the new stylesheet link
                        // link.dataset.type = "bootstrap";
                        // head.appendChild(link);
                        // console.log(`"%PUBLIC_URL%/assets/css/bootstrap.min.css`, `${process.env.REACT_APP_CLIENT_BASE_URL}assets/css/bootstrap.rtl.min.css`)
                        window.location.href = window.location.pathname
                        setLoader(false);
                      }}
                    >
                      {ele.name}
                    </span>
                  </li>
                ))}
              </ul>
            </div> */}
              <div class="dropdown customDropdown userProfileDropdown ms-xl-5 ms-sm-4 ms-2">
                <span
                  class="dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="userIcon">
                    <img
                      src={
                        URL.imageUrl +
                        profileData?.item?.level?.level?.featuredImage?.default
                      }
                      alt=""
                    />
                  </span>
                  <span className="profileName">
                    <span style={{ caretColor: "transparent" }}>
                      {profileData?.item?.gamerjiName}
                    </span>
                  </span>
                </span>

                <ul class="dropdown-menu">
                  <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => {navigate(routes.myProfile);
                        eventTracking(events.my_profile);
                      }}
                    >
                      <span class="icon me-2">
                        {/* <img class="icon-16 me-2" src={Profile} alt="" /> */}
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8 8C6.2352 8 4.8 6.5648 4.8 4.8C4.8 3.0352 6.2352 1.6 8 1.6C9.7648 1.6 11.2 3.0352 11.2 4.8C11.2 6.5648 9.7648 8 8 8ZM11.0064 8.53838C12.0992 7.65918 12.8 6.312 12.8 4.8C12.8 2.1488 10.6512 0 8 0C5.3488 0 3.2 2.1488 3.2 4.8C3.2 6.312 3.9008 7.65918 4.9936 8.53838C2.0664 9.63838 0 12.356 0 16H1.6C1.6 12 4.4712 9.6 8 9.6C11.5288 9.6 14.4 12 14.4 16H16C16 12.356 13.9336 9.63838 11.0064 8.53838Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {t("right_hand_drop_down.my_profile")}
                    </span>
                  </li>
                  <li>
                    <span class="dropdown-item cursor" onClick={handlerJVC}>
                      <span class="icon me-2">
                        {/* <img class="icon-16 me-2" src={JVC} alt="" /> */}
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2713_65580)">
                            <path
                              d="M4.63412 14.6662C3.75966 14.6625 2.92166 14.3153 2.30079 13.6995C1.99036 13.3953 1.74375 13.0323 1.57539 12.6316C1.40703 12.231 1.32031 11.8007 1.32031 11.3662C1.32031 10.9316 1.40703 10.5013 1.57539 10.1007C1.74375 9.70001 1.99036 9.33697 2.30079 9.03282L4.70079 6.63282C5.00494 6.32239 5.36798 6.07578 5.76864 5.90742C6.1693 5.73906 6.59953 5.65234 7.03412 5.65234C7.46872 5.65234 7.89894 5.73906 8.2996 5.90742C8.70026 6.07578 9.0633 6.32239 9.36746 6.63282C9.46374 6.7612 9.51049 6.92001 9.49911 7.08008C9.48774 7.24015 9.419 7.39075 9.30553 7.50422C9.19205 7.6177 9.04146 7.68643 8.88138 7.69781C8.72131 7.70919 8.5625 7.66244 8.43412 7.56615C8.06026 7.1997 7.55763 6.99444 7.03412 6.99444C6.51062 6.99444 6.00798 7.1997 5.63412 7.56615L3.23412 9.96615C2.86282 10.3375 2.65422 10.8411 2.65422 11.3662C2.65422 11.8913 2.86282 12.3948 3.23412 12.7662C3.60543 13.1375 4.10902 13.3461 4.63412 13.3461C5.15922 13.3461 5.66282 13.1375 6.03412 12.7662L7.53412 11.1995C7.6625 11.1032 7.82131 11.0565 7.98138 11.0678C8.14146 11.0792 8.29205 11.1479 8.40553 11.2614C8.519 11.3749 8.58774 11.5255 8.59911 11.6856C8.61049 11.8456 8.56374 12.0044 8.46746 12.1328L6.96746 13.6995C6.34659 14.3153 5.50859 14.6625 4.63412 14.6662Z"
                              fill="currentColor"
                            />
                            <path
                              d="M8.96667 10.3319C8.0922 10.3282 7.2542 9.98104 6.63333 9.36523C6.53705 9.23685 6.4903 9.07805 6.50168 8.91797C6.51305 8.7579 6.58179 8.6073 6.69526 8.49383C6.80874 8.38035 6.95933 8.31162 7.11941 8.30024C7.27948 8.28887 7.43829 8.33561 7.56667 8.4319C7.94053 8.79835 8.44316 9.00361 8.96667 9.00361C9.49017 9.00361 9.99281 8.79835 10.3667 8.4319L12.7667 6.0319C13.138 5.6606 13.3466 5.157 13.3466 4.6319C13.3466 4.1068 13.138 3.6032 12.7667 3.2319C12.3954 2.8606 11.8918 2.652 11.3667 2.652C10.8416 2.652 10.338 2.8606 9.96667 3.2319L8.46667 4.79856C8.33829 4.89485 8.17948 4.9416 8.01941 4.93022C7.85934 4.91885 7.70874 4.85011 7.59526 4.73664C7.48179 4.62316 7.41305 4.47256 7.40168 4.31249C7.3903 4.15242 7.43705 3.99361 7.53333 3.86523L9.03333 2.29857C9.6543 1.69002 10.4903 1.35112 11.3598 1.35551C12.2292 1.3599 13.0618 1.70723 13.6765 2.32202C14.2913 2.9368 14.6387 3.76937 14.6431 4.6388C14.6474 5.50823 14.3085 6.34427 13.7 6.96523L11.3 9.36523C10.6791 9.98104 9.84113 10.3282 8.96667 10.3319Z"
                              fill="currentColor"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2713_65580">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      {t("right_hand_drop_down.join_via_invite_code")}
                    </span>
                  </li>
                  {/* <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => {setShowDLR(true);
                        eventTracking(events.DAILY_LOGIN_REWARD);}}
                    >
                      <span class="icon">
                        <img class="icon-16 me-2" src={JVC} alt="" />
                      </span>
                      {t("right_hand_drop_down.daily_login_rewards")}
                    </span>
                  </li> */}
                  {/* <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => {setShowReferFriend(true);
                        eventTracking(events.REFERE);
                      
                      }}
                    >
                      <span class="icon">
                        <img class="icon-16 me-2" src={Friend} alt="" />
                      </span>
                      {t("right_hand_drop_down.refer_a_friend")}
                    </span>
                  </li> */}
                  {/* <li>
                    <span
                      className="dropdown-item cursor"
                      onClick={handleApplyPromoCode}
                    >
                      <span class="icon">
                        <img class="icon-16 me-2" src={PromoCode} alt="" />
                      </span>
                      {t("right_hand_drop_down.apply_promo_code")}
                    </span>
                  </li> */}
                  <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => {setShowGamerjiPointsMOdal(true);
                        eventTracking(events.gamerji_point);}}
                    >
                      <span class="icon me-2">
                        {/* <img class="icon-16 me-2" src={Points} alt="" /> */}
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 0V16H12.9962V0H3ZM12.5059 15.5098H11.2827C11.7852 15.2105 12.2067 14.789 12.5059 14.2865V15.5098ZM9.74412 15.4998V12.7479H12.496C12.3775 14.211 11.2072 15.3813 9.74412 15.4998ZM12.5059 1.75109H12.2507V2.24134H12.5059V12.2577H9.25391V15.5098H3.49022V2.24134H3.74541V1.75109H3.49022V0.49025H12.5059V1.75109Z"
                            fill="currentColor"
                          />
                          <path
                            d="M4.74641 1.75H4.24609V2.24025H4.74641V1.75Z"
                            fill="currentColor"
                          />
                          <path
                            d="M5.74641 1.75H5.24609V2.24025H5.74641V1.75Z"
                            fill="currentColor"
                          />
                          <path
                            d="M6.74641 1.75H6.24609V2.24025H6.74641V1.75Z"
                            fill="currentColor"
                          />
                          <path
                            d="M7.74641 1.75H7.24609V2.24025H7.74641V1.75Z"
                            fill="currentColor"
                          />
                          <path
                            d="M8.75031 1.75H8.25V2.24025H8.75031V1.75Z"
                            fill="currentColor"
                          />
                          <path
                            d="M9.75031 1.75H9.25V2.24025H9.75031V1.75Z"
                            fill="currentColor"
                          />
                          <path
                            d="M10.7503 1.75H10.25V2.24025H10.7503V1.75Z"
                            fill="currentColor"
                          />
                          <path
                            d="M11.7503 1.75H11.25V2.24025H11.7503V1.75Z"
                            fill="currentColor"
                          />
                          <path
                            d="M11.7508 3.75391H4.24609V4.24416H11.7508V3.75391Z"
                            fill="currentColor"
                          />
                          <path
                            d="M11.7508 5.00391H4.24609V5.49416H11.7508V5.00391Z"
                            fill="currentColor"
                          />
                          <path
                            d="M11.7508 6.25391H4.24609V6.74416H11.7508V6.25391Z"
                            fill="currentColor"
                          />
                          <path
                            d="M11.7508 7.50391H4.24609V7.99416H11.7508V7.50391Z"
                            fill="currentColor"
                          />
                          <path
                            d="M11.7508 8.75391H4.24609V9.24416H11.7508V8.75391Z"
                            fill="currentColor"
                          />
                          <path
                            d="M11.7508 10.0078H4.24609V10.4981H11.7508V10.0078Z"
                            fill="currentColor"
                          />
                          <path
                            d="M11.7508 11.2578H4.24609V11.7481H11.7508V11.2578Z"
                            fill="currentColor"
                          />
                          <path
                            d="M8.99919 12.5078H4.24609V12.9981H8.99919V12.5078Z"
                            fill="currentColor"
                          />
                          <path
                            d="M8.99919 13.7578H4.24609V14.2481H8.99919V13.7578Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {t('right_hand_drop_down.gamerji_points')}
                    </span>
                  </li>

                  <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => {navigate("/home/legality");
                      eventTracking(events.legality);}}
                    >
                      <span class="icon me-2">
                        {/* <img class="icon-16 me-2" src={Legality} alt="" /> */}
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.96869 3.2915L5.85519 7.479H6.40419L4.39219 3.021H7.49869V13.4065C6.85869 13.43 3.59219 14.49 3.59219 14.49V15H12.3722V14.4555C12.3722 14.4555 9.03319 13.406 8.53019 13.406V3.021H11.5377L9.52519 7.479H10.0742L11.9607 3.2915L13.8472 7.479H14.3962L12.3722 2.996V2.479H8.99519C8.99519 1.8025 8.54169 1.25 8.01269 1.25C7.45319 1.25 7.03019 1.834 7.03019 2.479H3.59119V2.9225L1.53319 7.479H2.08219L3.96869 3.2915ZM9.49069 7.9895C9.49069 9.336 10.4247 10.6305 11.9602 10.6305C13.4612 10.6305 14.4297 9.336 14.4297 7.9895C14.4302 7.9915 9.49069 7.9915 9.49069 7.9895ZM6.36969 7.9895C6.36969 7.992 1.43019 7.992 1.43019 7.9895C1.43019 9.336 2.36419 10.6305 3.89969 10.6305C5.40119 10.6305 6.36969 9.336 6.36969 7.9895Z"
                            fill="currentColor"
                            stroke="currentColor"
                            stroke-width="0.2"
                          />
                        </svg>
                      </span>
                      {t("right_hand_drop_down.legality")}
                    </span>
                  </li>
                

                  {/* <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => {setShowFavoriteGame(true);
                        eventTracking(events.FAV_GAMES);}}
                    >
                      <span class="icon">
                        <img class="icon-16 me-2" src={Favorite} alt="" />
                      </span>
                      {t("right_hand_drop_down.favorite_game")}
                    </span>
                  </li> */}
                  <li>
                    <span
                      class="dropdown-item cursor"
                      onClick={() => {setLogoutModal(true);
                        
                          eventTracking(events.LOGOUT);
                        }}
                    >
                      <span class="icon me-2">
                        {/* <img class="icon-16 me-2" src={LogoutIcon} alt="" /> */}
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.7735 2.11329C9.79439 2.11197 10.7981 2.37617 11.686 2.87996C12.5739 3.38374 13.3156 4.10978 13.8381 4.9868C14.3607 5.86382 14.6462 6.86166 14.6666 7.88235C14.687 8.90304 14.4416 9.91149 13.9545 10.8087C13.4674 11.7059 12.7553 12.461 11.8882 12.9998C11.0211 13.5387 10.0288 13.8428 9.00865 13.8822C7.98852 13.9217 6.97566 13.6952 6.06953 13.2249C5.16339 12.7547 4.39512 12.0568 3.84016 11.2L4.7735 10.46C5.35677 11.4036 6.25504 12.1105 7.30937 12.4557C8.3637 12.8008 9.50612 12.7618 10.5345 12.3457C11.5629 11.9295 12.4109 11.163 12.9285 10.1818C13.4461 9.20059 13.6 8.06791 13.3628 6.98418C13.1257 5.90045 12.5128 4.93553 11.6327 4.26016C10.7526 3.5848 9.66198 3.24252 8.5538 3.2939C7.44561 3.34527 6.39132 3.78699 5.57747 4.54088C4.76362 5.29478 4.24268 6.31226 4.10683 7.41329H5.7335L3.5335 9.60662L1.3335 7.41329H2.92016C3.06556 5.96157 3.74488 4.61558 4.82639 3.63631C5.90789 2.65704 7.31452 2.11428 8.7735 2.11329ZM10.4868 6.91995C10.63 6.91995 10.7674 6.97685 10.8687 7.07811C10.9699 7.17938 11.0268 7.31674 11.0268 7.45995V10.06C11.0268 10.2049 10.9692 10.344 10.8667 10.4465C10.7642 10.549 10.6251 10.6066 10.4802 10.6066H7.3335C7.19496 10.5997 7.06475 10.5384 6.97113 10.4361C6.87751 10.3337 6.82806 10.1986 6.8335 10.06V7.33329C6.82977 7.19691 6.87911 7.0644 6.97113 6.96368C7.06315 6.86296 7.19067 6.80187 7.32683 6.79329V6.30662C7.32668 6.07927 7.37519 5.85453 7.4691 5.64749C7.563 5.44044 7.70012 5.25589 7.87126 5.10623C8.04239 4.95656 8.24357 4.84526 8.46128 4.77978C8.679 4.71431 8.9082 4.69618 9.1335 4.72662C9.52736 4.78624 9.88626 4.98663 10.1437 5.29063C10.4011 5.59463 10.5396 5.98165 10.5335 6.37995V6.91995M9.7935 6.35995C9.79877 6.20231 9.7581 6.04654 9.67642 5.9116C9.59475 5.77666 9.47561 5.66839 9.3335 5.59995C9.20574 5.53679 9.06429 5.50637 8.92186 5.51142C8.77944 5.51648 8.6405 5.55685 8.51754 5.6289C8.39458 5.70096 8.29146 5.80244 8.21744 5.92423C8.14342 6.04602 8.10083 6.18429 8.0935 6.32662V6.91995H9.80683L9.7935 6.35995Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {t("right_hand_drop_down.logout")}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {showJVC && (
        <JoinViaInviteCodeModal showJVC={showJVC} setShowJVC={setShowJVC} />
      )}
      {showAPC && (
        <ApplyPromoCodeModel
          showAPC={showAPC}
          setShowAPC={setShowAPC}
          setShowSPC={setShowSPC}
        />
      )}
      {showSPC && (
        <SuccessPromoCodeModal showSPC={showSPC} setShowSPC={setShowSPC} />
      )}
      {showJoinModal && (
        <JoinModal
          showJoinModal={showJoinModal}
          setShowJoinModal={setShowJoinModal}
          handlerWinning={handlerWinning}
          handlerJoinNow={handlerJoinNow}
        />
      )}
      {showWinningModal && (
        <WinningBreakUp
          showWinningModal={showWinningModal}
          setShowWinningModal={setShowWinningModal}
        />
      )}
      {showTournamentModal && (
        <TournamentModal
          showTournamentModal={showTournamentModal}
          setShowTournamentModal={setShowTournamentModal}
          setShowTournamentConfirmationModal={
            setShowTournamentConfirmationModal
          }
        />
      )}

      {showTournamentConfirmationModal && (
        <TournamentConfirmationModal
          showTournamentConfirmationModal={showTournamentConfirmationModal}
          setShowTournamentConfirmationModal={
            setShowTournamentConfirmationModal
          }
        />
      )}
    
      {logoutModal && (
        <Logout
          logoutModal={logoutModal}
          setLogoutModal={setLogoutModal}
          logout={logout}
        />
      )}
      {showGamerjiPointsModal && (
        <GamerjiPoints
          showGamerjiPointsModal={showGamerjiPointsModal}
          setShowGamerjiPointsMOdal={setShowGamerjiPointsMOdal}
        />
      )}
   
    </>
  );
};

export default Navbar;
