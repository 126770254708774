import "./App.css";
import "../src/assets/css/style.css";
import "../src/assets/css/newStyle.css";


import Avartar from "./Components/Auth/Avartar";
import { Navigate, Route, Routes } from "react-router";

import { routes } from "./Utilities/routesFb";
import HomePage from "./Components/HomePage/HomePage";
import GameTournament from "./Components/HomePage/Tournament/GameTournament";
import Main from "./Components/HomePage/Main";
import TournamentDetails from "./Components/HomePage/Tournament/TournamentDetails";


import WorldOfEsports from "./Components/HomePage/WorldOfEsports/WorldOfEsports";
import WOEMain from "./Components/HomePage/WorldOfEsports/WOEMain";
import News from "./Components/HomePage/WorldOfEsports/AllNews";
import NewsDetails from "./Components/HomePage/WorldOfEsports/NewsDetails";
import AllPopularVideos from "./Components/HomePage/WorldOfEsports/AllPopularVideos";
import AllTopProfiles from "./Components/HomePage/WorldOfEsports/AllTopProfiles";
import LeaderBoard from "./Components/HomePage/LeaderBoard/LeaderBoard";
import MyContest from "./Components/HomePage/MyContest/MyContest";
import Contest from "./Components/HomePage/Contest/Contest";
import ContestDetails from "./Components/HomePage/Contest/ContestDetails";

import Protected from "./Protected";
import CustomerCare from "./Components/HomePage/CustomerCare/CustomerCare";
import CustomerCareForm from "./Components/HomePage/CustomerCare/CustomerCareForm";
import CRMMain from "./Components/HomePage/CustomerCare/CRMMain";
import RewardStore from "./Components/HomePage/RewardStore/RewardStore";
import FreeGames from "./Components/HomePage/FreeGames/FreeGames";

import Legality from "./Components/HomePage/Legality/Legality";
import CoinStore from "./Components/HomePage/CoinStore/CoinStore";
import MyProfile from "./Components/Profile/MyProfile";
import TicketDetails from "./Components/HomePage/CustomerCare/TicketDetails";
import SubscriptionTypeList from "./Components/Subscription/SubscriptionTypeList";
import UserProfile from "./Components/Profile/UserProfile";

import LoginWithToken from "./Components/Auth/LoginWithToken";
import { useState } from "react";

function App() {

  const [blog, setBlog] = useState(null);
  const token = localStorage.getItem("token");
  // console.log(token, window.location.pathname)
  const isDetailsFilled = localStorage.getItem("isDetailsFilled");
// console.log(isDetailsFilled)
const urlParams = new URLSearchParams(window.location.search);
  const codeFromURL = urlParams.get('code');
  if(codeFromURL)
    localStorage.setItem('code', codeFromURL)
  


  const handleClickBlog = (item) => {
    setBlog(item);
  };

  return (
    <>
    {/* {console.log("", isDetailsFilled === "false", token)} */}
      <Routes>
        {/* <Route
         
          path={routes.home}
          element={
            token ? (
              isDetailsFilled === "false" ? (
                <Navigate replace to="/avatar" />
              ) : (
                <Navigate replace to="/home" />
              )
            ) : (
              <Navigate replace to="/login" />
            )
          }
        /> */}
        <Route
          exact
          path={routes.login}
          element={token ? isDetailsFilled === "false" ? (
            <Navigate replace to="/avatar" />
          ) : (
            <Navigate replace to="/home" />
          )  : 
          <LoginWithToken />}
        />
       
        {/* <Route exact path={routes.login} element={ <LoginWithToken />} /> */}

        <Route
          exact
          path={routes.avatar}
          element={<Protected Component={Avartar} />}
        />

        <Route
          exact
          path={routes.home}
          element={
            token ? (
              isDetailsFilled === "false" ? (
                <Navigate replace to="/avatar" />
              ) : (
                <HomePage />
              )
            ) : (
              <Navigate replace to="/login" />
            )
          }
        >
          <Route path="" element={<Protected Component={Main} />} />
          <Route path={routes.gameTournament} element={<Protected Component={GameTournament} />} />
          <Route
            path={routes.tournamentDetails}
            element={<Protected Component={TournamentDetails} />}
          />

          <Route exact path={routes.worldOfEsports} element={<Protected Component={WOEMain} />}>
            <Route
              exact
              path=""
              element={<WorldOfEsports handleClickBlog={handleClickBlog} />}
            />

            <Route
              exact
              path={routes.news}
              element={<News handleClickBlog={handleClickBlog} />}
            />
            <Route
              exact
              path={routes.newsDetails}
              element={<NewsDetails blog={blog} />}
            />
            <Route
              exact
              path={routes.popularVideos}
              element={<AllPopularVideos />}
            />
            <Route
              exact
              path={routes.topProfiles}
              element={<AllTopProfiles />}
            />
          </Route>

          <Route exact path={routes.leaderBoard} element={<LeaderBoard />} />

    
          <Route exact path={routes.myContest} element={<MyContest />} />
          <Route exact path={routes.freeGames} element={<FreeGames />} />

          <Route exact path={routes.gameContest} element={<Contest />} />
          <Route
            exact
            path={routes.contestDetails}
            element={<ContestDetails />}
          />
          <Route exact path={routes.customerCare} element={<CRMMain />}>
            <Route exact path="" element={<CustomerCare />} />

            <Route
              exact
              path={routes.raiseACompaint}
              element={<CustomerCareForm />}
            />
            <Route exact path={routes.ticketDetails} element={<TicketDetails />} />

          </Route>
          <Route exact path={routes.rewardStore} element={<RewardStore />} />
          <Route exact path={routes.legality} element={<Legality />} />
          <Route exact path={routes.coinStore} element={<CoinStore />} />
          <Route exact path={routes.myProfile} element={<MyProfile />} />
          <Route exact path={routes.userProfile} element={<UserProfile />} />


          <Route exact path={routes.getSubscriptionTypeList} element={<SubscriptionTypeList />} />
     



        </Route>

        {/* <Route exact path="/" 
          // element={<LandingMain />} 
          element={token ? <Navigate replace to="/home" /> : <LandingMain />}
        /> */}
        <Route exact path="/" 
          element={token ? <Navigate replace to="/home" /> : <Navigate replace to="/login" /> }
        />
        



      </Routes>
    </>
  );
}

export default App;
