import React, { memo, useEffect, useReducer, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { Outlet } from 'react-router'
import { func } from '../../Utilities/logFunc'
import { isUserPremium } from '../../Utilities/controller'

import { toast } from 'react-toastify'
import ProfileDataProvider from './ProfileContext'


const HomePage = () => {
const [activeNav, setActiveNav]= useState('')
// localStorage.setItem("premiumUser",true)
const [isActiveMenu, seActiveMenu] = useState(false)
const [isValid, setIsValid] = useState(false)

// useEffect(()=>{
//   console.log("without valid ")
// },[])
useEffect(()=>{
  // console.log("Home Page ")
  setIsValid(true)

},[])

  return (
      <main class="dashboardMain">
        {console.log("****dagass")}
        <ProfileDataProvider>
        <Sidebar setActiveNav={setActiveNav} seActiveMenu={seActiveMenu} isActiveMenu={isActiveMenu}/>
  
        <Navbar  activeNav={activeNav} seActiveMenu={seActiveMenu} isActiveMenu={isActiveMenu} />
       
        {/* <Outlet /> */}
        {
          isValid &&  <Outlet />
        }
        </ProfileDataProvider>
      </main>

  )
}

export default memo(HomePage)