import React, { useEffect, useRef, useState } from 'react'
// import CrossIcon from '../../assets/images/cross-icon-svg.svg'
import premiumIcon from '../../assets/images/gamerji-coin.svg'
// import premiumIcon from "../../assets/images/subscription_icon_2.png";
// import CrossIconWhite from '../../assets/images/cross-icon-svg-white.svg'
import TickIconPurple from '../../assets/images/tick-icon-svg-purple.svg'
import { func } from '../../Utilities/logFunc'
import { toast, ToastContainer } from 'react-toastify'
import { getSubscriptionTypeList, createSubscription} from '../../Utilities/controller'
import SuccessPop from './SuccessPop'

import { useProfileDataContext } from '../HomePage/ProfileContext'
import { useTranslation } from 'react-i18next'
import Loader from "../Common/Loader";
import { encryptKey } from "../../Utilities/encryptDecrypt";
import { eventTracking } from '../../firebaseAnalytics'
import { events } from '../../Utilities/appEvents'

const SubscriptionTypeList = () => {
  const [list, setList] = useState([])
  const [showPayment, setShowPayment] = useState(false)
  const [showPaymentOption, setShowPaymentOption] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const { liteProfile }  =  useProfileDataContext()
  const { t } = useTranslation();
  const contentRef = useRef(null);
  console.log(liteProfile)
  useEffect(()=>{
    EventTracking(); 
  },[]);
  const EventTracking=()=>{
    eventTracking(events.premium_menu);
  }
 
  const fetchList = async()=>{
    if(liteProfile?.country?._id) {
      let payload = {
          country :liteProfile?.country?._id,
          company:process.env.REACT_APP_COMPANY_ID
      }
      try{
          // alert('jaimin');
          const res = await getSubscriptionTypeList(payload)
          console.log("subscription list 13", res)
          setList(res?.data?.data?.[0]?.param?.data)
      }
      catch(error){
        toast.error(error?.response?.data?.errors?.[0]?.msg);
      }
    }
  }
  const handlerBuyPlan = async(pack) =>{

    eventTracking(events.subcription_plan, {
      OP_PlanAmount: pack.amount,
    });

    let profile=JSON.parse(localStorage.getItem('profile'))
    if(profile?.item?.phone && profile?.item?.phoneCode){
      window.location.href = "http://ooqa.peerlinear.tech/ps/oredo/msisdn=" + profile?.item?.phoneCode?.replace("+", "") + profile?.item?.phone
    } else {
      toast.error("Can't find the msisdn");
    }
    // try {
    //   setLoading(true);
    //   let phone = liteProfile.item.phoneCode.replace("+","") + liteProfile.item.phone
    //   const encPhone = await encryptKey(phone);
    //   let payload = {
    //     planId: xsollId,
    //     msisdn: encPhone
    //   }
    //   const res = await createSubscription(payload);
    //   console.log(res.data);
    //   if(res.data.success) {
    //     setLoading(false);
    //     toast.success(res.data.msg)
    //   } else {
    //     setLoading(false);
    //     toast.error(res.data.msg)
    //   }

    // } catch (error) {
    //   setLoading(false);
    //   toast.error(error.response.data.msg)
    // }
  }
  useEffect(()=>{
      fetchList()
      if (document.documentElement.dir == "rtl" && contentRef?.current ) {
      const contentWidth = contentRef?.current?.clientWidth - contentRef?.current?.scrollWidth;
        contentRef?.current.scrollTo({
          left: contentWidth,
          behavior: 'smooth',
        });
      }
      else {
        const contentWidth = contentRef?.current?.scrollWidth - contentRef?.current?.clientWidth;
        contentRef?.current.scrollTo({
          left: contentWidth,
          behavior: 'smooth',
        });
      }
      
  },[])
  if (loading) return <Loader />;
  return (
    <div className='content'>
      {/* <ToastContainer /> */}
      <div className='inner'>
      <div class="row gy-5">
        <div class="col-12">
          <div class="mb-2 d-flex align-items-center justify-content-between">
            <h2 class="h3 bodyBoldFont mb-0">{t("premium.gamerji_premium")}</h2>
          </div>
            <div class={ list.length > 0 ? "pricingTable":"" } ref={contentRef}>
            {  list.length > 0 ? 
              <table class="table m-0">
                <thead>
                  <tr>
                    <th></th>
                    {/* <th scope="col">
                      <h4 class="text-dark">{t('tournaments.free')}</h4>
                    </th> */}
                    <th>
                      <img class="mb-4 icon-100" src={premiumIcon} alt="" />
                      <h4 class="text-black"><b>{list?.[0]?.planName}</b></h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  
                    <tr>
                      <td colspan={2}>{t("premium.features")}</td>
                    </tr>
                    <tr>
                      <td>{t("premium.free_joining")}</td>
                      {/* <td>{t("premium.daily1")}</td> */}
                      <td style={{color:'var(--color-navy-blue)'}}><b>{t("premium.unlimited_free_joining")}</b></td>
                    </tr>
                    {/* <tr>
                      <td>{t("premium.statistic")}</td>
                      <td><img src={TickIconPurple} alt="" /></td>
                    </tr> */}
                    
                </tbody>
                <tfoot>
                  <tr>
                    <td></td>
                    {
                      localStorage.getItem("premiumUser") === 'false' && localStorage.getItem("isUserSubscribed") === 'false'?
                      <td>
                        <div class="row justify-content-center text-dark">
                          <div className="pricing-tab-div">
                          <div className='col-12' style={{textAlign: 'center'}}>
                            {/* <ul
                              class="nav nav-pills  d-flex justify-content-between auth-nav"
                              id="myTab"
                              role="tablist"
                            >
                              {
                                list?.[0]?.packs.map((value, index) => (

                                  <li class="nav-item" role="presentation"  style={{ width: `${100 / list?.[0]?.packs.length}%` }}>
                                    <button
                                    className={` ${activeIndex === index ? "nav-link active" : "nav-link"
                                  }`}
                                      // class="nav-link active"
                                      onClick={() => setActiveIndex(index)}
                                      id="prizePool-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#prizePool-tab-pane"
                                      type="button"
                                      role="tab"
                                      aria-controls="prizePool-tab-pane"
                                      aria-selected="true"
                                      style={{ width: "100%" }}
                                    >
                                      {value?.packName}
                                    </button>
                                  </li>
                                ))
                              }
                            </ul> */}
                            </div>
                        <div className='col-12' style={{textAlign: 'center'}}>
                          {/* <h5 class="mb-3 text-dark">{list?.[0]?.currencyID?.symbol}{value.amount}/ {t("premium.month")}</h5> */}
                          <button style={{width: "50%"}} onClick={()=> handlerBuyPlan(list?.[0]?.packs[activeIndex])} className="btn btn-primary">
                          {t('premium.subscription_for', {amount: `${list?.[0]?.currencyID?.symbol} ${list?.[0]?.packs[activeIndex].amount}`})}
                          </button>
                        </div>
                          </div>
                        </div>
                      </td>
                      :
                      localStorage.getItem("premiumUser") === 'true' && localStorage.getItem("isUserSubscribed") === 'true' ? 
                      <td>
                        <div class="row justify-content-center text-dark">
                          <ul class="nav nav-pills  d-flex justify-content-between sub-nav">
                            <div className='col-12' style={{textAlign: 'center'}}>
                              <span class="btn btn-outline-golden active-button" style={{cursor: 'initial'}}>{t("premium.currently_subscribed")}</span>
                            </div>
                          </ul>
                        </div>
                      </td>
                      : <td>
                      <div class="row justify-content-center text-dark">
                        <ul class="nav nav-pills  d-flex justify-content-between sub-nav">
                          <div className='col-12' style={{textAlign: 'center'}}>
                            <span class="btn btn-outline-golden active-button" style={{cursor: 'initial'}}>{t("premium.currently_active")}</span>
                          </div>
                        </ul>
                      </div>
                    </td>
                    }
                  
                  </tr>
                </tfoot>
              </table>
              : 
              <div class="row justify-content-center text-center">
                <div className='d-flex'>
                  <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}><h3>{t('notFound.no_records_found')}</h3></div>
                </div>
              </div>
            }
            </div>
            
        </div>
        </div>
        {
          <SuccessPop />
        }
       
      </div>
    </div>
  )
}

export default SubscriptionTypeList
