import CryptoJS from 'crypto-js'
export const encryptKey = async (text) => {

    var ciphertext = CryptoJS.AES.encrypt(text, process.env.REACT_APP_ENCRYPT_KEY).toString();
    // console.log("text code",text,ciphertext);
    return ciphertext;
}
export const decryptKey = async (text) => {
    var bytes  = CryptoJS.AES.decrypt(text, process.env.REACT_APP_ENCRYPT_KEY);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
}